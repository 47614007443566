<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="right" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <p class="split">基本信息</p>

                <el-form-item label="商品名称" prop="title">
                    <el-input type="text" auto-complete="off" v-model="form.title" placeholder="请输入商品名称"></el-input>
                </el-form-item>
                <el-form-item label="商品分类" prop="classId">
                    <!-- <el-cascader v-model="form.classId" :options="ClassOne" @active-item-change="getNodes" :props="props" @change="changePid"></el-cascader> -->
                    <el-cascader v-model="form.classId" :props="casprops" ref="addrCas" @change="changePid" :key="addrKey"></el-cascader>

                </el-form-item>
                <el-form-item label="品牌" prop="brand_id">
                    <el-select v-model="form.brand_id" placeholder="请选择品牌" class="handle-select mr10">
                        <el-option v-for="items in brand" :key="items.id" :label="items.name" :value="items.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品简介" prop="description">
                    <el-input type="text" auto-complete="off" v-model="form.description" placeholder="请输入商品简介">
                    </el-input>
                    <span class="desc">最多输入30个字，于展示商品详情页展示</span>
                </el-form-item>
                <el-form-item label="服务描述" prop="service_desc">
                    <el-input type="text" auto-complete="off" v-model="form.service_desc" placeholder="请输入服务描述，留空则不显示">
                    </el-input>
                    <span class="desc">最多输入30个字，用于展示商品详情页展示</span>
                </el-form-item>
                <el-form-item label="商品标签" prop="tag">
                    <el-tag v-for="(item,index) in tagList" :key="item.index" closable :disable-transitions="true" @close="handleClose(index)">{{item.name}}</el-tag>
                    <el-autocomplete ref="saveTagInput" v-if="tagVisible" class="inline-input" v-model="tag" :fetch-suggestions="queryTagSearch" placeholder="输入内容" :trigger-on-focus="false" @select="handleTagSelect"></el-autocomplete>
                    <el-button v-else class="button-new-tag" size="small" @click="showtag">添加标签</el-button>
                    <!-- </div> -->
                </el-form-item>
                <!--图片上传 -->
                <el-form-item label="图片" prop="form">
                    <Imgupload v-model="form.thumbs" :ImgVisible="ImgVisible" @changeImg="changeImg" @changetext="changetext" />
                    <span class="span_gray">建议尺寸750*1125像素,可拖动图片调整位置</span>
                </el-form-item>
                <p class="split">价格库存</p>
                <div>
                    <div v-for="(specKey, keyIndex) in form.spec" :key="keyIndex" class="specifbox">
                        <div class="spec-key">
                            <el-form-item label="规格名" style="margin-top:20px" prop="specKey" :rules="rules.specKey">

                                <!-- <el-select v-model="specKey.id" placeholder="请选择商品规格" class="handle-select mr10" @change="((val)=>{changeSpecKey(val,specKey)})">
                                    <el-option v-for="items in specification" :key="items.id" :label="items.name" :value="items.id"></el-option>
                                </el-select> -->

                                <el-select v-model="specKey.id" placeholder="请选择商品规格" class="handle-select mr10" @change="((val)=>{handleSelect(val,specKey,keyIndex)})">
                                    <el-option v-for="(items,index) in specification" :key="index" :label="items.name" :value="items.id"></el-option>
                                </el-select>

                                <!-- <el-autocomplete class="inline-input" value-key="name" v-model.trim="specKey.name" :fetch-suggestions="querySearch" placeholder="请输入内容" @blur.native.capture="splitText(specKey.name,keyIndex)" @select="(item) => {handleSelect(item, keyIndex)}"></el-autocomplete> -->

                                <span class="spec-key-remove" @click="removeSpecKey(keyIndex)">×</span>
                            </el-form-item>
                        </div>

                        <div style="display:flex;" class="spec-box">
                            <span class="el-form-item__label" style="width: 100px;padding-right: 0;">规格值：</span>
                            <span v-for="(specValue,valueIndex) in form.spec_item" :key="valueIndex">
                                <span class="input-span" v-if="specValue.specid === specKey.id">
                                    <el-form-item :rules="{required: true,message: '规格值不能为空',trigger: 'blur'}" label-width="10px">
                                        <el-input v-model="specValue.title" @input="changeColor" @change="changeSpecValueInput()"></el-input>
                                        <i class="el-icon-delete deletebtn" @click="removeSpecValueInput(valueIndex)"></i>
                                    </el-form-item>
                                    <el-form-item :rules="{  required: true, message: '图片不能为空', trigger: 'blur'}" v-if="checkInfo">
                                        <el-button style="margin-bttom:20px" @click.prevent="choiceImg(valueIndex)">选择图片</el-button>
                                        <div class="img-box">
                                            <img v-if="specValue.thumb!==''" :src="specValue.thumb" class="avatar" />
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </div>
                                        <p style="color:red;width:202px;height:40px"><span v-show="!specValue.thumb">请添加图片</span></p>
                                    </el-form-item>
                                </span>
                            </span>

                            <span class="button" style="margin-left:20px">
                                <span style="cursor: pointer;color:blue;font-size:14px" @click="addSpecValue(specKey.id)">添加规格值</span>
                            </span>
                        </div>
                    </div>
                    <el-form-item label="" style="margin-top:20px">
                        <el-button :disabled="btnDisabled" @click="addSpecKey">添加规格项目(必填)</el-button>
                        <el-checkbox v-if="0===1" class="check" v-model="checkInfo">添加规格图片</el-checkbox>
                    </el-form-item>
                </div>
                <el-form-item class="infotable" label="规格信息" prop="info" v-if="form.option.length">
                    <el-form :model="form" ref="form">
                        <el-table @cell-click="cellClick" v-loading="loading" :data="form.option" element-loading-text="数据加载中" stripe border ref="infoTable" style="margin-top:20px">
                            <!--<el-table-column align="center" :label="form.infoList[0].specName1" width="100" v-if="form.infoList[0].specName1">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px">{{ scope.row.value1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="size" :label="form.infoList[0].specName2" width="100" align="center" v-if="form.infoList[0].specName2">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px">{{ scope.row.value2 }}</span>
                                </template>
                            </el-table-column>-->
                            <el-table-column v-for="(specKey, keyIndex) in form.spec" :label="specKey.name" :key="keyIndex">
                                <template slot-scope="scope">
                                    <div>
                                        {{ scope.row.spec[keyIndex] }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="thumb" label="缩略图" width="150" align="center">
                                <template slot="header" slot-scope="scope">
                                    <span>缩略图</span>
                                </template>
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.thumb ==null || scope.row.thumb.length===0" @click="changeOptionItemImg(true,scope.$index)"> 选择图片</el-button>
                                    <img class="option-item-thumb" @click="changeOptionItemImg(true,scope.$index)" v-if="scope.row.thumb !=null && scope.row.thumb.length>20" :src="scope.row.thumb" alt="" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="costprice" label="供货价" width="150" align="center">
                                <template slot="header" slot-scope="scope">
                                    <span>供货价</span>
                                    <span v-if="!showsupplyprice" class="blue" @click="showsupplyprice=true">批量</span>
                                    <div class="inline" v-else>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.costprice"></el-input>
                                        <span style="cursor: pointer;" @click="saveP(scope.column,scope.$index,'showsupplyprice')">确定</span>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.costprice" placeholder="请输入价格"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.costprice }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="productprice" label="建议分销价" width="150" align="center">
                                <template slot="header" slot-scope="scope">
                                    <span>建议分销价</span>
                                    <span v-if="!showdistr" class="blue" @click="showdistr=true">批量</span>
                                    <div class="inline" v-else>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.productprice"></el-input>
                                        <span style="cursor: pointer;" @click="saveP(scope.column,scope.$index,'showdistr')">确定</span>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.productprice" placeholder="请输入价格"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.productprice }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="marketprice" label="市场价" width="150" align="center">
                                <template slot="header" slot-scope="scope">
                                    <span>市场价</span>
                                    <span v-if="!showmarket" class="blue" @click="showmarket=true">批量</span>
                                    <div class="inline" v-else>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.marketprice"></el-input>
                                        <span style="cursor: pointer;" @click="saveP(scope.column,scope.$index,'showmarket')">确定</span>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.marketprice" placeholder="请输入价格"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.marketprice }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="stock" label="库存" width="150" align="center">
                                <template slot="header" slot-scope="scope">
                                    <span>库存</span>
                                    <span v-if="!showstock" class="blue" @click="showstock=true">批量</span>
                                    <div class="inline" v-else>
                                        <el-input v-model="scope.column.stock" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                        <span style="cursor: pointer;" @click="saveP(scope.column,scope.$index,'showstock')">确定</span>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.stock" placeholder="请输入数量"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.stock }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="weight" label="重量" width="150" align="center">
                                <template slot="header" slot-scope="scope">
                                    <span>重量</span>
                                    <span v-if="!showweight" class="blue" @click="showweight=true">批量</span>
                                    <div class="inline" v-else>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.weight"></el-input>
                                        <span style="" @click="saveP(scope.column,scope.$index,'showweight')">确定</span>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.weight" placeholder="请输入重量"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.weight }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="productsn" label="商品编码" align="center">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input size="small" @blur="loseFcous(scope.$index, scope.row)" v-model="scope.row.productsn" placeholder="请输入商品编码"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.productsn }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="goodssn" label="商品条码" align="center">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row.seen">
                                        <el-input size="small" @blur="loseFcous(scope.$index, scope.row)" v-model="scope.row.goodssn" placeholder="请输入商品条码"></el-input>
                                    </el-form-item>
                                    <span style="margin-left: 10px" v-else>{{ scope.row.goodssn }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </el-form-item>

                <el-form-item label="运费" prop="freight">
                    <el-radio-group v-model="feightRadio" @change="changeFreight">
                        <el-radio :label="1" style="display:block;margin-bottom:20px">
                            固定运费
                            <el-input type="number" :disabled="!disabled" v-model="form.freight" placeholder="请输入运费"></el-input>
                            <span style="margin-left: 5px;">￥</span>
                        </el-radio>
                        <el-radio :label="2">
                            使用模板
                            <el-select :disabled="disabled" v-model="form.freightTemplateId" placeholder="请选择模板" class="handle-select mr10">
                                <el-option v-for="items in templates" :key="items.id" :label="items.name" :value="items.id"></el-option>
                            </el-select>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="虚拟销量">
                    <el-input type="number" v-model="form.virtual_sales" min="0" placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item label="会员储值卡抵扣比例" prop="charge_card_pay_percent">
                    <el-input type="number" v-model="form.charge_card_pay_percent" min="0" placeholder="请输入会员储值卡抵扣比例"></el-input>
                </el-form-item>

                <p class="split">商品介绍</p>
                <el-form-item label="商品介绍" prop="content">
                    <quill-editor class="editor" ref="myTextEditor" v-model="form.content" :options="editorOption">
                    </quill-editor>
                </el-form-item>
                <p class="split" style="margin-top:110px;">销售属性</p>
                <el-form-item label="上架处理" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">直接上架售卖</el-radio>
                        <el-radio :label="1">等待手动上架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否热门" prop="is_hot">
                    <el-radio-group v-model="form.is_hot">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否新品" prop="is_new">
                    <el-radio-group v-model="form.is_new">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否推荐" prop="is_recommand">
                    <el-radio-group v-model="form.is_recommand">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否多规格" prop="is_spec">
                    <el-radio-group v-model="form.is_spec">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="预售" prop="advance">
                    <el-switch
                        v-model="form.advance"
                        class="exio-switch"
                        active-text="开"
                        inactive-text="关"
                        active-color="#409EFF"
                        inactive-color="#e8e4f3"
                    ></el-switch>
                    <div v-if="form.advance">
                        <span style="margin-right:20px">全款预售</span>
                        <el-date-picker v-model="form.ctime" type="date" placeholder="选择日期"></el-date-picker>
                        <p>只允许设置3~90天内的时间</p>
                    </div>
                </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="saveData">提交</el-button>
            </div>

        </div>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
        <!-- 选择sku图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="optionSelectVisible" @toggleImg="toggleOptionItemImg" @getImg="getOptionItemImg"></Picture>
        </div>
        <!-- 新增规格 -->
        <el-dialog :title="title" :visible.sync="specVisible" width="600px" v-if="specVisible" class="dialog_box">
            <el-form ref="specForm" :model="specForm" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="规格名称" prop="name">
                    <el-input v-model="specForm.name" placeholder="请输入规格名称"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="specVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSpec">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 选择详情图片对话框 -->
        <div class="detail-img">
            <Picture :ImgVisible="detailImgVisible" @toggleImg="toggleDetailImg" @getImgs="getDetailImg" :multiple="true"></Picture>
        </div>
        <input id="detail-img" hidden @click="showDetailImg" />

        <el-upload ref="imageListUpload" class="select-uploader" hidden multiple :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
            上传图片
        </el-upload>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Imgupload from '@/components/Imgupload';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor, Quill } from 'vue-quill-editor';
import { Loading } from "element-ui";
import loadEvents from '@/utils/loading'
import { request } from '@/common/request';
import { deal } from '@/common/main';
import Picture from '@/components/Picture';
import bus from '@/common/bus';
import { nextTick } from 'vuedraggable';
import { IMAGE_UPLOAD_PRO_URL } from "../../common/const";

let loads = new loadEvents();

let BlockEmbed = Quill.import('blots/block/embed');
const Link = Quill.import('formats/link');
class ImageBlot extends BlockEmbed {
    static create(value) {
        let node = super.create();
        node.setAttribute('style', value.style);
        node.setAttribute('src', value.url);
        return node;
    }
    static value(node) {
        return {
            alt: node.getAttribute('style'),
            url: node.getAttribute('src')
        };
    }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';



Quill.register(ImageBlot, true) // 插入图片
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['image'],
    ['clean']                                         // remove formatting button
]
export default {
    components: {
        Breadcrumb,
        Pagination,
        Imgupload,
        quillEditor,
        Picture
    },
    data() {
        const that = this;
        return {
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            header: {
                toke: localStorage.getItem('token'),
                platform: 'admin',
            },
            specValue: {},
            text: 'image',
            ImgVisible: false,
            specVisible: false,
            optionSelectVisible: false,  //显示 sku选择缩略图
            optionSelectIndex: 0,  //当前sku选择缩略图的行号
            tag: '',
            tagList: [],
            tagVisible: false,
            queryParams: {
                page: 1,
                pagesize: -1
            },
            id: null,
            detailImgVisible: false,
            editorOption: {
                placeholder: '请输入商品描述',
                content: this.content,
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': function(value) {
                                if (value) {
                                    // document.querySelector('#detail-img').click()
                                    document.querySelector('.select-uploader input').click();
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    }
                }
            },
            feightRadio: 1,  //
            freight: null,
            template: '',
            shipment: [
                {
                    ty: '1',
                    name: '南京'
                }, {
                    ty: '2',
                    name: '上海'
                }, {
                    ty: '3',
                    name: '江苏'
                }
            ],
            // 规格值输入框数量
            templates: [
                {
                    ty: '1',
                    name: '模板1'
                }, {
                    ty: '2',
                    name: '模板2'
                }, {
                    ty: '3',
                    name: '模板3'
                }
            ], // 运费模板数据
            checkInfo: false,
            str: '',
            showsupplyprice: false,
            showdistr: false,//批量操作弹框
            showmarket: false,
            showstock: false,
            showweight: false,
            limit: 9, // 图片上传数量
            loading: false,
            title: '',
            specification: [],
            menuList: ['商品', '编辑商品'],
            specForm: {},
            form: {
                specId1: null,
                specList1: [{ value: '', thumb: '' }],
                freight: 0,
                service_desc:'',
                freightTemplateId:-1,  //运费的模板
                uppershelf: 1,
                infoList: [],
                classId: [],
                thumbs: [],
                virtual_sales: 0,

                option: [], //规格信息
                spec: [], //规格名信息
                spec_item: [] //规格值信息
            },
            rules: {
                title: [{
                    required: true,
                    message: '商品名称不能为空',
                    trigger: 'blur'
                }],
                classId: [{
                    required: false,
                    message: '商品分类不能为空',
                    trigger: 'blur'
                }],
                brand_id: [{
                    required: false,
                    message: '商品品牌不能为空',
                    trigger: 'blur'
                }],
               /* freight: [{
                    required: true,
                    message: '运费不能为空',
                    trigger: 'blur',
                },
                    /!*{
                        pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                        message: "只能输入整数或小数点后两位数字"
                    }*!/
                ],*/
                description: [{
                    required: true,
                    message: '商品描述不能为空',
                    trigger: 'blur'
                }, {
                    max: 30,
                    message: "最多输入30个字符",
                },],
                specifications: [{
                    required: true,
                    message: '商品规格不能为空',
                    trigger: 'blur'
                }],
                costprice: [{
                    required: true,
                    message: '供货价不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                productprice: [{
                    required: true,
                    message: '建议分销价不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                marketprice: [{
                    required: true,
                    message: '市场价不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                stock: [{
                    required: true,
                    message: '库存不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^[1-9]\d*$',
                    message: "只能输入非零的正整数"
                }],
                weight: [{
                    required: true,
                    message: '重量不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                productsn: [{
                    required: true,
                    message: '商品编码不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^[A-Za-z0-9]+$',
                    message: "只能输入数字或英文"
                }],
                goodssn: [{
                    required: true,
                    message: '商品条码不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^[A-Za-z0-9]+$',
                    message: "只能输入数字或英文"
                }],
            },
            pageInfo: {
                list: [],
            },

            pid: 0,
            ClassOne: [],
            props: {
                value: "id",
                label: "name",
                children: "children"
            },
            disabled: true,
            brand: [],
            casprops: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level, value } = node;
                    if (level == 0) {
                        request.get('/goods/classify/list', { pid: 0, page: 1, pagesize: -1, state: 2 }).then((res) => {
                            if (res.code == 1) {
                                let nodes = res.data.list
                                for (let i = 0; i < nodes.length; i++) {
                                    nodes[i]['value'] = nodes[i]['id']
                                    nodes[i]['label'] = nodes[i]['name']
                                    nodes[i]['leaf'] = level >= 2
                                }
                                resolve(nodes)
                            }
                        })
                    } else if (level == 1) {
                        request.get('/goods/classify/list', { pid: value, page: 1, pagesize: -1, state: 2 }).then((res) => {
                            if (res.code == 1) {
                                let nodes = res.data.list
                                for (let i = 0; i < nodes.length; i++) {
                                    nodes[i]['value'] = nodes[i]['id']
                                    nodes[i]['label'] = nodes[i]['name']
                                    nodes[i]['leaf'] = level >= 2
                                }
                                resolve(nodes)
                            }
                        })
                    } else {
                        request.get('/goods/classify/list', { pid: value, page: 1, pagesize: -1, state: 2 }).then((res) => {
                            if (res.code == 1) {
                                let nodes = res.data.list
                                for (let i = 0; i < nodes.length; i++) {
                                    nodes[i]['value'] = nodes[i]['id']
                                    nodes[i]['label'] = nodes[i]['name']
                                    nodes[i]['leaf'] = level >= 2
                                }
                                resolve(nodes)
                                /*  防止第一次渲染的时候没有地址文本信息  */
                                // let t = setTimeout(() => {
                                //     let nod = that.$refs.addrCas.getCheckedNodes();
                                //     console.log(that.$refs.addrCas.getCheckedNodes(), 'nod')
                                //     let label = nod[0].pathLabels
                                //     that.addrText = label[0] + label[1] + label[2]
                                //     clearTimeout(t)
                                // }, 2000)
                            }
                        })
                    }
                }
            },
            addrKey: 10,
            showCasc: false,
            addrText: '',
        };
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id != this.id) {
                    if (to.query.id) {
                        this.id = to.query.id;
                    }
                    this.loadData();
                }
                //深度监听，同时也可监听到param参数变化
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        btnDisabled() {
            return false
            /*let flg = false
            flg = this.form.specList2 && this.form.specList2.length > 0 ? true : false
            return flg*/
        }
    },
    created() {
        this.title = this.$route.query.title
        this.getBrandType()
        // this.getClassOne()
        this.getSpec()
        this.getExpressTemplateList()

    },
    methods: {

        // 输入规格失去焦点（用来添加规格）
        splitText(val, index) {
            if (val == '') {//选择的时候
                return false;
            }

            let finds = this.specification.find(value => value.name == val)
            if (finds == undefined) {//判断规格列表中没有的，则为新添加的
                let url = '/goods/spec/add';
                let specForm = {
                    name: val
                }
                //处理时间
                request.post(url, specForm).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success("规格添加成功")
                        this.form.spec[index].id = ret.data.id;
                        this.form.spec[index].name = ret.data.name;
                        this.form.spec[index].source_id = ret.data.source_id;
                        this.form.spec[index].source_name = ret.data.source_name;
                    } else {
                        this.$message.error(ret.msg);
                        this.$message.error("添加规格失败");
                    }
                });
            }
        },

        //图片上传
        handleAvatarSuccess(res, file, fileList) {
            let len = fileList.length;
            let successCount = 0;
            for (let file of fileList) {
                if (file.status === 'success') {
                    successCount += 1;
                } else {
                    break;
                }
            }

            if (len === successCount) {
                this.$refs.imageListUpload && this.$refs.imageListUpload.clearFiles()
                for (let file of fileList) {
                    // 获取富文本组件实例
                    let quill = this.$refs.myTextEditor.quill;
                    // 获取光标所在位置
                    let length = quill.getSelection().index
                    // quill.insertEmbed(length, 'image', res.data.uri)
                    quill.insertEmbed(length, 'image', {
                        style: 'display:block;',
                        url: file.response.data.uri
                    })
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                }
            }
        },
        beforeAvatarUpload(file) {
            // loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },

        // 可编辑单元格
        loseFcous(index, row) {
            // this.$set(row, 'seen', false)
        },
        cellClick(row, column) {
            this.$set(row, 'seen', true)
        },

        changePid(val) {

            if (val) {
                this.form.pcate = val[0]
                this.form.cate = val[1]
                this.form.tcate = val[2]
            }
        },
        // 获取一级分类
        // getClassOne() {
        //     this.ClassOne = []
        //     // 获取商品分类列表
        //     return new Promise((resolve, reject) => {
        //         let vue = this
        //         request.get('/goods/classify/list', { pid: 0, page: 1, pagesize: -1, state: 2 }).then(ret => {
        //             if (ret.code == 1) {
        //                 vue.loading = false;
        //                 let data = ret && ret.data && ret.data.list || []
        //                 data.map(item => {
        //                     vue.$set(item, "name", item.name);
        //                     vue.$set(item, "children", []);
        //                 });
        //                 vue.ClassOne = data;

        //             } else {
        //                 vue.$message.error(ret.msg);
        //             }
        //             resolve();
        //         });

        //     });


        // },
        // 获取二级分类

        // getClassItem(val, text) {
        //     return new Promise((resolve, reject) => {
        //         let vue = this

        //         request.get('/goods/classify/list', { pid: text ? text : val, page: 1, pagesize: -1, state: 2 }).then(ret => {
        //             if (ret.code == 1) {
        //                 vue.loading = false;
        //                 let data = ret && ret.data && ret.data.list || []
        //                 data.map(item => {
        //                     this.$set(item, "name", item.name);
        //                     if (!text) {
        //                         vue.$set(item, "children", []);
        //                     }
        //                 });
        //                 if (!text) {
        //                     vue.ClassOne.map((item, i) => {
        //                         if (item.id === val) {
        //                             item.children = data;
        //                         }
        //                     });
        //                 } else {
        //                     vue.ClassOne.map((item, i) => {
        //                         if (item.id === val) {
        //                             item.children.map((city, idx) => {
        //                                 if (city.id = text) {
        //                                     city.children = data;
        //                                 }
        //                             });
        //                         }
        //                     });
        //                 }
        //             } else {
        //                 vue.$message.error(ret.msg);
        //             }

        //             resolve();
        //         });

        //     });
        // },

        getNodes(val) {
            console.log(val)
            if (val.length === 1) {
                this.getClassItem(val[0])
            } else {
                this.getClassItem(val[0], val[1])
            }

        },
        // 获取品牌
        getBrandType() {
            request.get('/goods/brand/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.brand = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取运费模板
        getExpressTemplateList() {
            request.get('/common/express/template/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code === 1) {
                    this.templates = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取规格列表
        getSpec() {
            request.get('/goods/spec/list', { page: 1, pagesize: -1 }).then(ret => {
                if (ret.code == 1) {
                    this.specification = ret.data.list
                    this.specification.unshift({
                        id: 0,
                        name: '新增'
                    });
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 选择的规格
        handleSelect(val, specKey, index) {
            if (val == 0) {
                this.specVisible = true;
            } else {
                let data = this.specification.find(item => item.id == val)
                this.form.spec.splice(index, 1, data);
            }
        },
        querySearch(queryString, cb) {

            var restaurants = this.specification;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);

        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };

        },

        // 选择规格
        changeSpecKey(val, index) {
            console.log(val, index)
            if (val === 0) {
                this.specVisible = true
                if (index === 'specList1') {
                    this.$set(this.form, index, [{ value: '', thumb: '' }])
                    this.$set(this.form, 'specId1', null)
                } else {
                    this.$set(this.form, index, [{ value: '' }])
                    this.$set(this.form, 'specId2', null)
                }
            } else {
                let text = ''
                this.specification.map(item => {
                    if (item.id === val) {
                        return text = item.name
                    }
                })
                let _this = this
                if (index === 'specList1') {
                    this.$set(this.form, index, [{ value: '', thumb: '' }])
                    this.$set(this.form, 'specName1', text)
                } else {
                    this.$set(this.form, index, [{ value: '' }])
                    this.$set(this.form, 'specName2', text)
                }
            }

        },


        // 改变input
        changeColor() {
            this.$forceUpdate()
        },
        //  table更新
        changeSpecValueInput() {
            this.updateOption()



        },
        // 删除规格值
        removeSpecValueInput(specValueIndex) {
            this.form.spec_item.splice(specValueIndex, 1)
            this.updateOption()

            /* this.form[index].splice(key, 1)
             this.changeTable()*/

        },
        // 添加规格项目
        addSpecKey() {
            this.form.spec.push({
                id: null,
                name: ""
            })

            // this.$set(this.form, 'specId2', null)
            // this.$set(this.form, 'specList2', [{ value: '' }])
        },
        // 规格名去重
        removeSpecKey(specKeyIndex) {
            this.form.spec.splice(specKeyIndex, 1)
        },
        // 增加规格值输入框
        addSpecValue(specKeyId) {
            this.form.spec_item.push({
                goods_id: parseInt(this.id),
                id: null,
                source_id: "",
                source_name: "",
                specid: specKeyId,
                title: "",
            })
            this.updateOption()

            /*if (index === 0) {
                this.form.specList1.push({
                    value: '',
                    thumb: ''
                });
            } else {
                this.form.specList2.push({
                    value: '',
                });
            }*/
        },
        descartes(list) { // 笛卡尔积算法
            // parent上一级索引;count指针计数
            var point = {}
            var result = []
            var pIndex = null
            var tempCount = 0
            var temp = []
            // 根据参数列生成指针对象
            for (let index in list) {
                if (typeof list[index] === 'object') {
                    point[index] = {
                        'parent': pIndex,
                        'count': 0
                    }
                    pIndex = index
                }
            }
            // 单维度数据结构直接返回
            if (pIndex == null) {
                return list
            }
            // 动态生成笛卡尔积
            while (true) {
                for (var index in list) {
                    tempCount = point[index]['count']
                    temp.push(list[index][tempCount])
                }
                // 压入结果数组
                result.push(temp)
                temp = []
                // 检查指针最大值问题
                while (true) {
                    if (point[index]['count'] + 1 >= list[index].length) {
                        point[index]['count'] = 0
                        pIndex = point[index]['parent']
                        if (pIndex == null) {
                            return result
                        }
                        // 赋值parent进行再次检查
                        index = pIndex
                    } else {
                        point[index]['count']++
                        break
                    }
                }
            }
        },
        updateOption() {
            var vue = this
            var list = []
            for (let specKey of vue.form.spec) {
                let value = []
                for (let specValue of vue.form.spec_item) {
                    if (specValue.specid === specKey.id) {
                        value.push(specKey.id + '-' + specValue.id + '|' + specKey.name + '-' + specValue.title)
                    }
                }
                list.push(value)
            }
            let ret = vue.descartes(list)

            let option = []

            for (let i = 0; i < ret.length; i++) {


                let specArr = []
                let specs = ''
                let title = ''

                let dArr = ret[i]
                for (let j = 0; j < dArr.length; j++) {
                    let dItem = dArr[j]
                    let kvArr = dItem.split('|')

                    specArr.push(kvArr[1].split('-')[1])
                    specs += kvArr[0] + (j !== dArr.length - 1 ? ',' : '')
                    title += kvArr[1] + (j !== dArr.length - 1 ? ',' : '')
                }

                var item = {
                    goods_id: parseInt(this.id),
                    id: null,
                    marketprice: null,
                    costprice: null,
                    price: null,
                    productprice: null,
                    goodssn: '',
                    productsn: '',
                    r: 0,
                    // spec: ["藏青色", "110cm"],
                    // specs: "45547-109741,45548-118987",
                    // title: "颜色分类-藏青色,参考身高-110cm",
                    spec: specArr,
                    specs: specs,
                    title: title,
                    state: 2,
                    stock: 0,
                    stock_lock: 0,
                    thumb: '',
                    weight: 0,
                }


                for (let oldOption of vue.form.option) {

                    let eq = true
                    for (let s of item.spec) {
                        if (oldOption.spec.indexOf(s) < 0) {
                            eq = false
                            break
                        }
                    }

                    if (eq) {
                        item.id = oldOption.id
                        item.marketprice = oldOption.marketprice
                        item.costprice = oldOption.costprice
                        item.price = oldOption.price
                        item.productprice = oldOption.productprice
                        item.goodssn = oldOption.goodssn
                        item.productsn = oldOption.productsn
                        item.r = oldOption.r
                        item.stock = oldOption.stock
                        item.stock_lock = oldOption.stock_lock
                        item.thumb = oldOption.thumb
                        item.weight = oldOption.weight

                    }
                }

                option.push(item)
            }

            this.$set(this.form, 'option', option)

        },


        changeFreight() {
            let str = this.feightRadio
            if (str === 1) {
                this.form.freightTemplateId = 0
                this.disabled = true
            } else {
                this.form.freight = 0.00
                this.disabled = false
            }
        },
        // changeFreightInput() {
        //     this.form.freight = this.freight
        // },
        // changeFreightSlect() {
        //     this.form.freight = this.template
        // },
        // 批量操作确定按钮
        saveP(column, index, val) {
            this[val] = false
            let _this = this
            let str = column.property
            this.form.option.map(item =>
                _this.$set(item, str, column[str])
            )
        },


        // 关闭tag
        handleClose(index) {
            this.tagList.splice(index, 1);
            this.state = ''
        },
        showtag() {
            this.tagVisible = true;
        },
        queryTagSearch(queryString, cb) {
            let results = []
            this.queryParams.name = queryString
            request.get('/common/tag/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    if (ret.data.list.length > 0) {
                        setTimeout(() => {
                            for (let i of ret.data.list) {
                                i.value = i.name;  //将想要展示的数据作为value
                            }
                            results = ret.data.list
                            cb(results);
                        }, 200);
                    } else {
                        request.post('/common/tag/add', { name: queryString, state: 2 }).then(ret => {
                            if (ret.code == 1) {
                                this.queryTagSearch(queryString, cb);
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    }

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 新增tag
        handleTagSelect(item) {
            this.tagVisible = false;
            this.tagList.push(item)
            let obj = {};
            let newArr = this.tagList.reduce((cur, next) => {
                obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
                return cur;
            }, [])
            this.tagList = newArr
            this.tag = null
        },

        loadData() {
            this.loading = true;
            let vue = this
            request.get('/goods/detail', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    // 处理多图
                    let thumbs = JSON.parse(ret.data.list.thumbs)
                    thumbs.unshift(ret.data.list.thumb)
                    this.form = ret.data.list;
                    this.form.thumbs = thumbs;

                    this.$set(this.form, 'spec', ret.data.spec)
                    this.$set(this.form, 'spec_item', ret.data.spec_item)
                    this.$set(this.form, 'option', ret.data.option)
                    // 处理邮费
                    if( this.form.freight > 0|| this.form.freight===0&& this.form.freightTemplateId===0){  //固定金额
                        this.feightRadio=1
                        this.disabled = true

                    }
                    if (this.form.freight===0&& this.form.freightTemplateId>0){ //使用了运费模板
                        this.feightRadio=2
                        this.disabled = false
                    }



                    /*this.form.infoList = [

                    ]
                    let Arr1 = this.form.infoList
                    let Arr2 = this.form.infoList
                    let Arr3 = this.form.infoList

                    let obj2 = {}
                    if (this.form.infoList[0].specName2) {
                        Arr1 = Arr1.reduce(function (aa, current) {
                            obj2[current.value2] ? '' : obj2[current.value2] = true && aa.push(current);
                            return aa
                        }, [])
                        let arr = []
                        Arr1.map(item => {
                            arr.push({
                                value: item.value2
                            })
                        })
                        this.$set(this.form, 'specId2', this.form.infoList[0].specId2)
                        this.$set(this.form, 'specName2', this.form.infoList[0].specName2)
                        this.$set(this.form, 'specList2', arr)
                    }
                    let arr = []

                    Arr2 = Arr2.reduce(function (aa, current) {
                        obj2[current.value1] ? '' : obj2[current.value1] = true && aa.push(current);
                        return aa
                    }, [])
                    Arr2.map(item => {
                        arr.push({
                            value: item.value1,
                            thumb: ''
                        })
                    })
                    Arr3 = Arr3.reduce(function (aa, current) {
                        obj2[current.thumb] ? '' : obj2[current.thumb] = true && aa.push(current);
                        return aa
                    }, [])
                    this.$set(this.form, 'specId1', this.form.infoList[0].specId1)
                    this.$set(this.form, 'specName1', this.form.infoList[0].specName1)
                    this.$set(this.form, 'specList1', arr)
                    this.form.specList1.map((item, index) => {
                        if (Arr3[index] && item.value === Arr3[index].value1) {
                            item.thumb = Arr3[index].thumb
                        } else {
                            item.thumb = ''
                        }
                    })*/

                    vue.form.classId = [ret.data.list.pcate, ret.data.list.cate, ret.data.list.tcate]

                    // vue.getClassOne().then(res => {
                    //     vue.getClassItem(vue.form.classId[0]).then(res => {
                    //         vue.getClassItem(vue.form.classId[0], vue.form.classId[1])
                    //     })
                    // })
                    this.$refs.form.clearValidate();

                    this.addrKey++;
                    this.showCasc = true;
                    console.log(this.addrKey, 'addrkey')


                } else {
                    this.$message.error(ret.msg);
                    this.showCasc = true;
                }
            });
        },
        changeTable(text, clickIndex, key, value) {
            let _this = this
            let Narr = []
            let obj = {}
            let fo1 = this.form.specList1 && this.form.specList1.length > 0 ? this.form.specList1[0].value !== '' : false
            let fo2 = this.form.specList2 && this.form.specList2.length > 0 ? this.form.specList2[0].value !== '' : false
            if (fo1 && !fo2) {
                this.form.specList1.map((item, index) => {
                    if (item.value) {
                        let obj = {
                            specId1: _this.form.specId1,
                            specName1: _this.form.specName1,
                            value1: item.value,
                            thumb: _this.form.specList1[index].thumb
                        }
                        Narr.push(obj)
                    }
                })
                _this.form.infoList = Narr
            }
            if (fo2 && !fo1) {
                this.form.specList2.map((item2, index2) => {
                    let obj = {
                        specId2: _this.form.specId2,
                        specName2: _this.form.specName2,
                        value2: item2.value,
                    }
                    Narr.push(obj)
                })
                _this.form.infoList = Narr
            }
            if (!fo2 && !fo1) {
                this.form.infoList = []
            }
            if (fo1 && fo2) {
                this.form.specList1.map((item, index) => {
                    this.form.specList2.map((item2, index2) => {
                        if (item.value && item2.value) {
                            let obj = {
                                specId1: _this.form.specId1,
                                specName1: _this.form.specName1,
                                value1: item.value,
                                specId2: _this.form.specId2,
                                specName2: _this.form.specName2,
                                value2: item2.value,
                                thumb: _this.form.specList1[index].thumb
                            }
                            Narr.push(obj)
                        }


                    })
                })
                _this.form.infoList = Narr
            }
            this.$forceUpdate()
        },

        changeBegin(r) {
            this.queryParams.begin_time = this.beginDate[0];
            this.queryParams.end_time = this.beginDate[1];
        },


        //新增编辑
        async saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {


                        if (this.form.spec.length <= 0) {
                            this.$message.error('请添加规格项目');
                            return false;
                        }

                        let url = '/goods/edit';
                        let thumbs = this.form.thumbs
                        let thumb = thumbs.shift()
                        thumbs = JSON.stringify(thumbs)

                        this.form.option.map((v) => {
                            v.costprice = parseFloat(v.costprice);
                            v.productprice = parseFloat(v.productprice);
                            v.marketprice = parseFloat(v.marketprice);
                            v.weight = parseFloat(v.weight);
                            v.r = parseInt(v.r);
                            v.stock = parseInt(v.stock);
                        })

                        var postdata = {
                            id: this.form.id,
                            title: this.form.title,
                            supplier_name: this.form.supplier_name != null ? this.form.supplier_name : '',
                            content: this.form.content,
                            thumb: thumb,
                            thumbs: thumbs,
                            costprice: this.form.option[0].costprice,
                            productprice: this.form.option[0].productprice,
                            marketprice: this.form.option[0].marketprice,
                            stock: this.form.option[0].stock, //所有库存总和
                            productsn: this.form.option[0].productsn,
                            is_recommand: this.form.is_recommand,
                            is_hot: this.form.is_hot,
                            is_new: this.form.is_new,
                            is_spec: this.form.is_spec,
                            state: this.form.state,
                            service_desc: this.form.service_desc,
                            description: this.form.description,
                            sales: this.form.sales,
                            pcate: this.form.pcate,
                            cate: this.form.cate,
                            tcate: this.form.tcate,
                            brand_id: this.form.brand_id,
                            r: this.form.r,

                            virtual_sales: this.form.virtual_sales,

                            freight: this.form.freight,
                            freightTemplateId: this.form.freightTemplateId,
                            spec: JSON.stringify(this.form.spec),
                            spec_item: JSON.stringify(this.form.spec_item),
                            option: JSON.stringify(this.form.option)
                        }

                        console.log(postdata)

                        request.post(url, postdata).then(ret => {
                            if (ret.code == 1) {
                                this.$message.success('编辑成功');
                                // this.loadData();
                                bus.$emit('close_current_tags', '/product-listEdit');
                                this.$router.push({ path: '/product-list' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });




                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        saveSpec() {
            if (this.$refs.specForm) {
                this.$refs.specForm.validate(valid => {
                    if (valid) {
                        var url = '/goods/spec/add';
                        //处理时间
                        request.post(url, this.specForm).then(ret => {
                            if (ret.code == 1) {
                                this.$message.success('新增成功');
                                this.specVisible = false
                                this.getSpec()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags', '/product-listEdit');
            this.$router.push({ path: '/product-list' })
        },
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        toggleOptionItemImg(val) { // 关闭 option选择img对话框
            this.optionSelectVisible = val;
        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            if (this.text === 'many') {
                if (this.form.thumbs.length < 9) {
                    this.form.thumbs.push(url)
                }
                else {
                    this.$message({ type: 'error', message: '最多上传九张图片' })
                }
            } else {
                this.$set(this.form.specList1[this.text], 'thumb', url)
                this.ImgVisible = false
            }
        },
        getOptionItemImg(url) {// 获取选择的img
            let index = this.optionSelectIndex
            this.form.option[index].thumb = url;
            this.form.option[index].thumbShow = true
        },
        changeImg(val) {
            this.ImgVisible = val
        },
        changeOptionItemImg(val, index) {  //index 当前的行
            this.optionSelectVisible = val;
            this.optionSelectIndex = index;  //设置当前选中的 option 行
        },
        changetext(val) {
            this.text = val
        },
        toggleDetailImg(val) { // 关闭 img对话框
            this.detailImgVisible = false;
        },
        getDetailImg(urlList) {
            // 获取富文本组件实例
            let quill = this.$refs.myTextEditor.quill
            for (let url of urlList) {
                // 获取光标所在位置
                let length = quill.getSelection().index

                // 插入图片  res.info为服务器返回的图片地址
                quill.insertEmbed(length, 'image', url)

                // 调整光标到最后
                quill.setSelection(length + 1)
            }

            this.detailImgVisible = false;
        },
        showDetailImg() {
            if (!this.detailImgVisible) {
                this.detailImgVisible = true;
            }
        }

    }
};
</script>

<style scoped>
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
    font-size: 28px;
    line-height: 100px;
}
.img-box {
    width: 100px;
    height: 100px;
    border: 1px solid #eeeeee;
    line-height: 110px;
    text-align: center;
    margin-top: 10px;
}

.option-item-thumb {
    width: 50px;
    height: 50px;
}
.desc {
    margin-left: 20px;
}

.check {
    display: inline-block;
    margin-left: 20px;
}

.check1 {
    display: inline-block;
    margin-right: 15px;
}

.el-form-item__conten {
    width: 100%;
}

.infotable {
    width: 100%;
}

.el-icon-circle-plus-outline {
    color: blue;
    font-size: 20px;
    /* margin-left: 20px; */
}
.el-icon-remove-outline {
    color: blue;
    font-size: 20px;
    margin-left: 20px;
}
.form-box {
    width: 100%;
}

.left {
    margin-left: 0;
}

.button {
    border: none;
    background-color: #ffffff;
    outline: none;
    display: flex;
    align-self: end;
    margin-top: 10px;
}

.blue {
    cursor: pointer;
    color: blue;
    margin-left: 10px;
}

able th > .cell {
    display: flex;
    align-items: center;
}

.inline {
    width: 100%;
}

.inline >>> .el-input__inner {
    width: 60px;
}

.split {
    font-weight: 900;
    width: 100%;
    line-height: 40px;
    background-color: #dddddd;
    margin: 20px 0;
    padding-left: 20px;
}

/* 文本编辑样式 */
.editor {
    width: 60%;
    height: 600px;
}

.editor >>> .ql-container {
    min-height: 300px;
}

/* 开关样式 */

.exio-switch >>> .el-switch__core {
    height: 20px !important;
    line-height: 20px !important;
    width: 46px !important;
}

.exio-switch >>> .el-switch__label--left {
    position: relative;
    left: 46px;
    color: #fff;
    z-index: -1111;
}

.exio-switch >>> .el-switch__label--right {
    position: relative;
    right: 46px;
    color: #fff;
    z-index: -1111;
}

.exio-switch >>> .el-switch__label--right.is-active {
    z-index: 1111;
    color: #fff !important;
}

.exio-switch >>> .el-switch__label--left.is-active {
    z-index: 1111;
    color: #fff !important;
}

.exio-switch >>> .el-switch__core:after {
    top: 2px;
}

.avatar-uploader {
    margin-top: 10px;
}
/* .input-span {
    position: relative;
    display: inline-block;
} */

.deletebtn {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    font-size: 20px;
}
.input-span:hover .deletebtn {
    opacity: 1;
}

.spec-key {
    position: relative;
}
.spec-key:hover .spec-key-remove {
    display: block;
}
.spec-key .spec-key-remove {
    display: none;
    position: absolute;
    top: 12px;
    left: 200px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 16px;
    background: hsla(0, 0%, 60%, 0.6);
    border-radius: 10px;
    text-indent: 0;
}

.specifbox {
    margin-top: 10px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}
</style>
